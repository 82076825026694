export const environment = {
  name: '#{ Environment }#',

  get isProduction() {
    return this.name === 'PROD';
  },

  apiBaseUrl: '#{portal-api-base-url}#',
  v3: {
    apiBaseUrl: '#{portal-v3-api-base-url}#',
  },
  goldBaseUrl: '#{app-goldmanagers-grpc-address}#',

  externalLinks: {
    dutchRegisterLink: '#{bootstrapper-dutch-register-link}#',
    englishRegisterLink: '#{bootstrapper-english-register-link}#',
    legacyCustomerPortalLink: '#{bootstrapper-legacy-customer-portal-link}#',
  },

  recruitmentSignalRUrl: '#{portal-api-base-url}#/eventsHub/recruitment',
  screeningSignalRUrl: '#{portal-api-base-url}#/eventsHub/screening',
};
