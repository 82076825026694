export const TENANT = '#{ b2c-tenant-name }#';
export const CLIENTID = '#{ b2c-web-client-id }#';

export const msalConfig = {
  clientId: CLIENTID,
  tenant: TENANT,
  redirectUri: `/`,
  scopes: ['offline_access', 'openid'],
  policies: {
    signUpSignIn: {
      name: '#{b2c-signin-policy-v3}#',
      get authority() {
        return `https://${TENANT}.b2clogin.com/${TENANT}.onmicrosoft.com/${this.name}`;
      },
    },
    resetPassword: {
      name: 'B2C_1A_PASSWORDRESET',
      get authority() {
        return `https://${TENANT}.b2clogin.com/${TENANT}.onmicrosoft.com/${this.name}`;
      },
    },
    editProfile: {
      name: 'B2C_1A_ProfileEdit',
      get authority() {
        return `https://${TENANT}.b2clogin.com/${TENANT}.onmicrosoft.com/${this.name}`;
      },
    },
  },
  protectedResources: [
    {
      scopes: [`#{ Platform:AzureAdB2C:Scope }#`],
      uri: '#{portal-api-base-url}#/web',
    },
    {
      scopes: [`#{ Platform:AzureAdB2C:Scope }#`],
      uri: '#{portal-api-base-url}#/mobile',
    },
    {
      scopes: [`#{ Platform:AzureAdB2C:Scope }#`],
      uri: '#{portal-v3-api-base-url}#/web',
    },
    {
      scopes: [`#{ Platform:AzureAdB2C:Scope }#`],
      uri: '#{app-goldmanagers-grpc-address}#/api',
    },
  ],
  authorityDomain: `${TENANT}.b2clogin.com`,
  get knownAuthorities() {
    return [this.authorityDomain];
  },
};
